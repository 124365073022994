import React from "react"
import { graphql } from 'gatsby'
import Head from "../components/Head"
import '../styles/index.css'

const IndexPage = () => (
  <Head title="Home" />
)

export const query = graphql`
query {
  prismic {
    allFooters {
      edges {
        node {
          instagram
          linkedin
          newsletter
          copyright
        }
      }
    }
    allProjects(sortBy: date_DESC) {
      edges {
        cursor,
        node {
          _meta {
            uid
          }
          date,
          name,
          thumbnail
        }
      }
    }
  }
}
`

export default IndexPage
